.descriptionTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: start;
  padding: 1rem;
  backdrop-filter: blur(30px);
  box-shadow: 0px 0px 30px rgba(227, 228, 237, 0.27);
  border: 2px solid rgba(255, 255, 255, 0.18);
}

.white-border .MuiOutlinedInput-root {
  fieldset {
    border-color: white !important;
  }
}